import { Tag } from '@chakra-ui/react'

import { FontFileType } from 'modules/api'

export const FontFileExtensionBadge = ({
  fileExtension,
}: {
  fileExtension: FontFileType
}) => {
  return (
    <Tag
      size="sm"
      justifyContent="center"
      background={
        fileExtension === FontFileType.Ttf ? 'trueblue.100' : 'shocking.100'
      }
    >
      {fileExtension.toUpperCase()}
    </Tag>
  )
}
