import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize } from '@gamma-app/ui'

import { WorkspaceRole } from 'modules/api'
import { workspaceRoleMap } from 'modules/workspaces/constants'

type WorkspaceRoleMenuProps = {
  selected: WorkspaceRole
  options: WorkspaceRole[]
  onSelect: (opt: WorkspaceRole) => void
  disabledOptions?: { disabledOption: WorkspaceRole; reason: string }[]
}

export const WorkspaceRoleMenu = ({
  selected,
  options,
  onSelect,
  disabledOptions = [],
}: WorkspaceRoleMenuProps) => {
  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        as={Button}
        variant="ghost"
        size="sm"
        rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
      >
        {capitalize(selected)}
      </MenuButton>
      <MenuList maxW="320px" zIndex="dropdown">
        <MenuOptionGroup
          defaultValue={selected}
          value={selected}
          type="radio"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              onSelect(value as WorkspaceRole)
            }
          }}
        >
          {options.map((option) => {
            const disabledOption = disabledOptions.find(
              (f) => f.disabledOption === option
            )
            return (
              <MenuItemOption
                key={option}
                value={option}
                isDisabled={!!disabledOption}
                pt={3}
              >
                <Tooltip
                  aria-label=""
                  label={disabledOption?.reason}
                  isDisabled={!disabledOption}
                  placement="right"
                  shouldWrapChildren // so tooltip shows on disabled buttons
                >
                  <Box lineHeight="base">
                    <Text fontSize="md">{workspaceRoleMap[option].title}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {workspaceRoleMap[option].description}
                    </Text>
                  </Box>
                </Tooltip>
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
