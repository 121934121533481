import {
  Alert,
  Box,
  Button,
  Code,
  Flex,
  Heading,
  ModalBody,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

const FontUploadInfoCell = ({
  uploadTip,
  children,
}: {
  uploadTip: JSX.Element
  children: ReactNode
}) => {
  return (
    <VStack align="stretch">
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="center"
        minH={32}
        userSelect="none"
        background="gray.50"
        borderRadius="md"
        p={4}
        mb={1}
      >
        <Stack spacing={0}>{children}</Stack>
      </Flex>
      <Text color="gray.700" fontSize="sm">
        {uploadTip}
      </Text>
    </VStack>
  )
}

const UploadWarning = () => {
  return (
    <Alert
      status="warning"
      margin="0 auto"
      maxW={412}
      lineHeight="var(--chakra-lineHeights-base)"
    >
      <Text fontSize="xs" color="yellow.800">
        <strong>Note:</strong> (1) By uploading fonts to Gamma, you confirm you
        have the rights to use them. (2) Variable fonts are not currently
        supported.
      </Text>
    </Alert>
  )
}

export const FontUploadInfoView = ({
  onFontFileUploadClick,
}: {
  onFontFileUploadClick: () => void
}) => {
  return (
    <ModalBody>
      <VStack mb="4" spacing="8" borderRadius="md">
        <Heading textAlign="center" size="md" fontWeight="600">
          <span role="img" aria-label="idea">
            💡
          </span>{' '}
          Tips for uploading font families
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} width="100%">
          <FontUploadInfoCell
            uploadTip={
              <>
                Add <strong>one family at a time</strong>. Add condensed or
                extended variants as separate families.
              </>
            }
          >
            <Heading fontSize="3xl" fontWeight="bold">
              Inter
            </Heading>
          </FontUploadInfoCell>
          <FontUploadInfoCell
            uploadTip={
              <>
                Add <strong>all weights and variants</strong> of a font family.
              </>
            }
          >
            <Text>
              inter-<strong>regular</strong>.ttf{' '}
              <Box color="green.400" as="span">
                <FontAwesomeIcon icon={duotone('check')} />
              </Box>
            </Text>
            <Text>
              inter-<strong>italic</strong>.ttf{' '}
              <Box color="green.400" as="span">
                <FontAwesomeIcon icon={duotone('check')} />
              </Box>
            </Text>
            <Text>
              inter-<strong>bold</strong>.ttf{' '}
              <Box color="green.400" as="span">
                <FontAwesomeIcon icon={duotone('check')} />
              </Box>
            </Text>
          </FontUploadInfoCell>
          <FontUploadInfoCell
            uploadTip={
              <>
                You can upload <strong>.ttf</strong> and/or{' '}
                <strong>.otf</strong> copies of each font style.
              </>
            }
          >
            <Text>
              <Text color="gray.600" as="span">
                <FontAwesomeIcon icon={regular('file-lines')} />
              </Text>{' '}
              ...-regular
              <Code colorScheme="purple">.ttf</Code>
            </Text>
            <Text>
              <Text color="gray.600" as="span">
                <FontAwesomeIcon icon={regular('file-lines')} />
              </Text>{' '}
              ...-regular
              <Code colorScheme="pink">.otf</Code>
            </Text>
          </FontUploadInfoCell>
        </SimpleGrid>
        <VStack spacing={4}>
          <Button
            leftIcon={
              <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
            }
            variant="solid"
            onClick={onFontFileUploadClick}
          >
            Choose font files
          </Button>
          <Text textAlign="center" fontSize="xs" color="gray.600">
            Gamma supports .ttf and .otf fonts
          </Text>
          <UploadWarning />
        </VStack>
      </VStack>
    </ModalBody>
  )
}
