import { Box, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentGrid, DocumentGridItem } from '@gamma-app/ui'
import NextLink from 'next/link'
import Router from 'next/router'
import { Dispatch, SetStateAction } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { useGetDocPreviewUrlQuery } from 'modules/api'
import {
  DeckCategoryHeader,
  SimplifiedCategory,
  SimplifiedDeckEntry,
  useGetInspirationCategories,
} from 'modules/example_decks'
import { getDocIdForEnv, getPreviewImageUrl } from 'modules/example_decks/utils'
import { useFeatureFlag, useFeatureFlagProvider } from 'modules/featureFlags'
import GammaLogo from 'publicImages/gamma-logo-profile-image.png'
import { generateDocUrl } from 'utils/url'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'

const InspirationItem = ({ deck }: { deck: SimplifiedDeckEntry }) => {
  const docIdToUse = getDocIdForEnv(deck)

  const url = generateDocUrl({ docId: docIdToUse as string, path: 'example' })

  return (
    <DocumentGridItem
      title={deck.title || ''}
      menuEnabled={true}
      href={url}
      id={docIdToUse as string}
      key={docIdToUse}
      createdTime={new Date().toISOString()}
      thumbnailImageUrl={{
        src: deck?.previewImageUrl,
        fallbackSrc: placeholderBackground.src,
      }}
      NextLink={NextLink}
      onFavorite={undefined}
      isTrashAndRestoreEnabled={false}
      createdByName={deck?.authorDisplayName || 'Gamma'}
      createdByProfileImageUrl={deck?.authorImageUrl || GammaLogo.src}
      isDuplicateEnabled={false}
    />
  )
}

const InspirationCategoryRow = ({
  category,
}: {
  category: SimplifiedCategory
}) => {
  if (!category) return <></>
  const categoryItems = category.decks
  return (
    <Box key={category.id} mb={8}>
      <DeckCategoryHeader category={category} />
      <DocumentGrid>
        {categoryItems?.map((deck) => {
          return <InspirationItem key={deck.docId} deck={deck} />
        })}
      </DocumentGrid>
    </Box>
  )
}
export const InspirationView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}) => {
  const { categories, loading } = useGetInspirationCategories()
  const examplesV2Enabled = useFeatureFlag('examplesV2')
  const inspirationDecksEnabled = useFeatureFlag('inspirationDecks')
  const { hasInitialized: featureFlagsInitialized } = useFeatureFlagProvider()

  if (
    featureFlagsInitialized &&
    (!examplesV2Enabled || !inspirationDecksEnabled)
  ) {
    Router.replace('/')
    return null
  }

  const { displayName, description, icon } = TabMap[SidebarTabs.INSPIRATION]

  return (
    <Box w="100%" data-testid="inspiration-view-container">
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading fontSize="lg" fontWeight="600" data-testid="docs-view-header">
          {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
      </TopbarWrapper>
      {loading && (
        <Box width="100%" data-testid="inspiration-skeleton">
          <DocumentGrid>
            <GridItem
              bg="linen.50"
              borderRadius="5px"
              height="240px"
              as={Skeleton}
              key={1}
            />
            <GridItem
              bg="linen.50"
              borderRadius="5px"
              height="240px"
              as={Skeleton}
              key={2}
            />
            <GridItem
              bg="linen.50"
              borderRadius="5px"
              height="240px"
              as={Skeleton}
              key={3}
            />
          </DocumentGrid>
        </Box>
      )}
      {!loading &&
        categories &&
        categories?.length > 0 &&
        categories?.map((category: SimplifiedCategory) => {
          return (
            <InspirationCategoryRow key={category.id} category={category} />
          )
        })}
    </Box>
  )
}
