import { HStack, StackProps, Text } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SidebarItemLabelProps {
  icon?: IconProp
  title: React.ReactNode | string
  faClassName?: string
  w?: StackProps['w']
}

export const SidebarItemLabel = ({
  icon,
  title,
  faClassName,
  w,
}: SidebarItemLabelProps) => {
  return (
    <HStack w={w}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          fixedWidth
          className={faClassName ? faClassName : undefined}
        />
      )}

      {typeof title === 'string' ? (
        <Text textOverflow="ellipsis" overflowX="hidden" ml={2}>
          {title}
        </Text>
      ) : (
        <>{title}</>
      )}
    </HStack>
  )
}
