export const makeRetryablePromise = <K>(
  fn: () => Promise<K>,
  options: { maxRetries: number; timeout: number }
): Promise<K> => {
  let count = 0
  return new Promise((resolve, reject) => {
    const doAttempt = () => {
      // only retry if this hangs
      const timeoutId = setTimeout(() => {
        count++
        count < options.maxRetries ? doAttempt() : reject('timed out')
      }, options.timeout)
      fn()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          clearTimeout(timeoutId)
        })
    }
    doAttempt()
  })
}
