import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Font } from 'modules/api'
import {
  findClosestFontWeight,
  getSortedFontFileWeights,
  getStyleCount,
  shouldBeItalicStyle,
} from 'modules/fonts/utils/utils'

import { FontGridItemWrapper } from './FontGridItemWrapper'
import { FontPreviewHeading } from './FontPreviewHeader'

type FontTileProps = {
  font: Font
  onClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
}
export const FontTile = ({
  font,
  onClick,
  onEditClick,
  onDeleteClick,
}: FontTileProps) => {
  const styleCount = getStyleCount(font?.fontFiles || [])
  const styleCountText = `${styleCount} ${
    styleCount === 1 ? 'style' : 'styles'
  }`
  const sortedFontWeights = getSortedFontFileWeights(font.fontFiles || [])
  const headingFontWeight = findClosestFontWeight(sortedFontWeights, 700) || 700
  const isItalicHeading = shouldBeItalicStyle(font?.fontFiles)

  return (
    <Stack>
      <FontGridItemWrapper
        bg="white"
        justifyContent="flex-start"
        p={6}
        onClick={onClick}
      >
        <Stack maxW="100%">
          <FontPreviewHeading
            fontWeight={headingFontWeight.toString()}
            isItalic={isItalicHeading}
            fontFamily={font.name}
            headingText={font.name}
          />
          {styleCount === 0 ? (
            <Text color="orange.600">
              <Box as="span" mr={1}>
                <FontAwesomeIcon icon={solid('warning')} />
              </Box>
              {styleCountText}
            </Text>
          ) : (
            <Text>{styleCountText}</Text>
          )}
        </Stack>
      </FontGridItemWrapper>
      <Flex>
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {font.name}
        </Text>
        <Spacer />
        <Menu>
          <MenuButton
            as={IconButton}
            isRound
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
            aria-label="Font family actions"
          />
          <MenuList>
            <MenuItem
              icon={<FontAwesomeIcon icon={regular('edit')} fixedWidth />}
              onClick={onEditClick}
            >
              Edit
            </MenuItem>
            <MenuDivider />
            <MenuItem
              color="red.500"
              icon={<FontAwesomeIcon icon={regular('trash')} fixedWidth />}
              onClick={onDeleteClick}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  )
}
