import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
export type GettingStartedOption = {
  title: string
  body: string | React.ReactElement
  colorScheme: string
  icon: FontAwesomeIconProps['icon']
  previewImage: string
  action: 'blankDeck' | 'templates' | 'welcomeDoc' | 'inspiration'
}

export const GETTING_STARTED_OPTIONS: Record<string, GettingStartedOption> = {
  createDeck: {
    title: 'Create a deck',
    body: 'Start in a blank deck. Add or paste in content, style it with themes, and see how it looks in present mode.',
    colorScheme: 'trueblue',
    previewImage:
      'https://cdn.gamma.app/zc87vhr30n8uf3n/4ca05c0140c141f48b1aa19b08be4218/original/ezgif.com-gif-maker-11.gif',
    icon: duotone('plus-large'),
    action: 'blankDeck',
  },
  viewTemplates: {
    title: 'View templates',
    body: 'Create your next presentation faster with our collection of fully customizable templates.',
    colorScheme: 'pink',
    previewImage:
      'https://cdn.gamma.app/_app_static/images/templates-final_46760af5df66666f.gif',
    icon: duotone('bolt'),
    action: 'templates',
  },
  tipsAndTricks: {
    title: 'Gamma tips & tricks',
    body: 'See how Gamma differs from traditional slide decks, and check out everything it can do.',
    colorScheme: 'teal',
    previewImage:
      'https://cdn.gamma.app/_app_static/images/welcome-final_0be8ada287ad993a.gif',
    icon: duotone('rectangle-history'),
    action: 'welcomeDoc',
  },
  inspiration: {
    title: 'Get inspired',
    body: 'Browse decks created by the Gamma team and other members of the Gamma community.',
    colorScheme: 'orchid',
    previewImage:
      'https://cdn.gamma.app/_app_static/images/inspiration-final_8e0f5e05e67209a2.gif',
    icon: duotone('book-open'),
    action: 'inspiration',
  },
}

export const GETTING_STARTED_OPTIONS_ORDERED = {
  mobile: [
    GETTING_STARTED_OPTIONS.tipsAndTricks,
    GETTING_STARTED_OPTIONS.inspiration,
  ],
  desktop: [
    GETTING_STARTED_OPTIONS.createDeck,
    GETTING_STARTED_OPTIONS.viewTemplates,
    GETTING_STARTED_OPTIONS.tipsAndTricks,
  ],
}
