import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuOptionGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  Skeleton,
  SkeletonCircle,
  Stack,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { isNil } from 'lodash'

import { config } from 'config'
import { useHealthCheck } from 'modules/api'
import { useUserContext } from 'modules/user'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { WORKSPACE_MEMBER_DISPLAY_NAME } from '../constants'
import { AccountDeleteModal } from './AccountDeleteModal'
import { AccountSettingsModal } from './AccountSettingsModal'

export const WorkspaceSwitcherSkeleton = () => {
  return (
    <HStack w="100%" px={2} pb={1}>
      <SkeletonCircle size="32px" />
      <Skeleton width="60%" height="20px" />
    </HStack>
  )
}
const WorkspaceRow = ({
  name,
  memberCount,
  isMultiline = true,
}: {
  name: string
  memberCount?: number | null
  isMultiline?: boolean
}) => {
  return (
    <HStack mr={4}>
      <Avatar
        name={name.replace(/\s/g, '')} // Remove spaces so that we only display one initial
        size="sm"
        bgColor="orchid.300"
        color="white"
      />
      <VStack
        spacing={0}
        alignItems="flex-start"
        {...(!isMultiline
          ? {
              noOfLines: 1,
            }
          : {})}
      >
        <Text
          fontSize="sm"
          fontWeight="600"
          wordBreak="break-word"
          m={0}
          p={0}
          {...(!isMultiline
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : {})}
          minWidth="0px"
          textAlign="left"
        >
          {name}
        </Text>
        {!isNil(memberCount) && (
          <Text fontSize="xs" color="gray.500" textAlign="left">
            {memberCount === 1 ? '1 member' : `${memberCount} members`}
          </Text>
        )}
      </VStack>
    </HStack>
  )
}

export const WorkspaceSwitcher = ({ onOpen }: { onOpen: () => void }) => {
  const { user, currentWorkspace, refetch, setCurrentWorkspaceId } =
    useUserContext()
  const { isConnected } = useHealthCheck()
  const {
    isOpen: isUserSettingsModalOpen,
    onOpen: onUserSettingsModalOpen,
    onClose: onUserSettingsModalClose,
  } = useDisclosure({ id: 'user-settings-modal' })
  const {
    isOpen: isAccountDeleteModalOpen,
    onOpen: onAccountDeleteModalOpen,
    onClose: onAccountDeleteModalClose,
  } = useDisclosure({
    id: 'account-delete-modal',
  })

  const menuListMinWidth = useGammaBreakpointValue({ md: '400px' })

  if (!user) return <></>

  const workspaces = user?.organizations
  if (!workspaces || !currentWorkspace) return <></>

  return (
    <>
      <Menu autoSelect={false}>
        <GammaTooltip
          label={isConnected ? '' : "You're offline"}
          aria-label={isConnected ? 'Workspace options' : "You're offline"}
        >
          <Box>
            <MenuButton
              as={Button}
              isDisabled={!isConnected}
              variant="ghost"
              rightIcon={
                isConnected ? (
                  <FontAwesomeIcon
                    icon={regular('angle-down')}
                    aria-label="Caret icon"
                  />
                ) : (
                  <FontAwesomeIcon icon={regular('cloud-slash')} />
                )
              }
              w="100%"
              colorScheme="blackAlpha"
              color="gray.800"
              cursor="pointer"
              _hover={{ color: 'trueblue.600' }}
              p={2}
              pr={4}
            >
              <Box width="100%">
                {currentWorkspace && (
                  <WorkspaceRow
                    name={currentWorkspace.name}
                    memberCount={null}
                    isMultiline={false}
                  />
                )}
              </Box>
            </MenuButton>
          </Box>
        </GammaTooltip>
        <MenuList
          minW={menuListMinWidth}
          zIndex="overlay"
          shadow="lg"
          maxW="400px"
        >
          <MenuItem px={2} cursor="pointer" onClick={onUserSettingsModalOpen}>
            <Avatar
              src={user.profileImageUrl}
              size="sm"
              name={user.displayName}
            />
            <Stack spacing={0} ml={2}>
              <Text fontSize="sm">{user.displayName}</Text>
              <Text fontSize="xs" color="gray.500">
                {user.email}
              </Text>
            </Stack>
            <Spacer />
            <FontAwesomeIcon
              size="sm"
              icon={solid('gear')}
              color="var(--chakra-colors-gray-500)"
            />
          </MenuItem>
          <MenuDivider />

          {workspaces && workspaces.length > 0 && (
            <MenuOptionGroup
              title="Workspaces"
              type="radio"
              defaultValue={currentWorkspace?.id}
              onChange={(id: string) => {
                setCurrentWorkspaceId(id)
              }}
            >
              {workspaces
                .filter(
                  (org) => !isNil(org) && !isNil(org.id) && !isNil(org.name)
                )
                .map((org) => {
                  return (
                    <MenuItemOption
                      key={org.id}
                      value={org.id}
                      icon={workspaces.length === 1 ? null : undefined}
                      isFocusable={workspaces.length === 1 ? false : true}
                    >
                      <HStack>
                        <Flex flex={1} w="100%">
                          <WorkspaceRow
                            name={org.name}
                            memberCount={null}
                            isMultiline={true}
                          />
                        </Flex>
                        {org.id === currentWorkspace?.id && (
                          <Flex>
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={onOpen}
                            >
                              Settings &amp; {WORKSPACE_MEMBER_DISPLAY_NAME}s
                            </Button>
                          </Flex>
                        )}
                      </HStack>
                    </MenuItemOption>
                  )
                })}
            </MenuOptionGroup>
          )}
          <MenuDivider />
          <MenuItem
            color="red.500"
            icon={<FontAwesomeIcon icon={regular('sign-out-alt')} fixedWidth />}
            onClick={() => {
              window.location.href = `${config.API_HOST}/logout`
            }}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
      {isUserSettingsModalOpen && (
        <AccountSettingsModal
          orgId={currentWorkspace.id}
          user={user}
          refetchUser={refetch}
          isOpen={isUserSettingsModalOpen}
          onClose={onUserSettingsModalClose}
          onAccountDeleteModalOpen={onAccountDeleteModalOpen}
        />
      )}
      {isAccountDeleteModalOpen && (
        <AccountDeleteModal
          user={user}
          isOpen={isAccountDeleteModalOpen}
          onClose={onAccountDeleteModalClose}
        />
      )}
    </>
  )
}
