import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'

import { Font, useGetWorkspaceFontsQuery } from 'modules/api'
import { useRequiredFeatureFlag } from 'modules/featureFlags/hooks/useRequiredFeatureFlag'
import { FontDeleteModal } from 'modules/fonts/components/FontDeleteModal'
import { FontGridItemWrapper } from 'modules/fonts/components/FontGridItemWrapper'
import { FontPreviewModal } from 'modules/fonts/components/FontPreviewModal'
import { FontTile } from 'modules/fonts/components/FontTile'
import { FontUploadModal } from 'modules/fonts/components/FontUploadModal'
import { FontLoader } from 'modules/theming/components/FontLoader'
import { useUserContext } from 'modules/user'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'

type FontUploadTileProps = {
  label: string
  icon: JSX.Element
  onClick: () => void
}
const FontUploadTile = ({ label, icon, onClick }: FontUploadTileProps) => {
  return (
    <FontGridItemWrapper bg="gray.50" onClick={onClick}>
      <Stack align="center">
        <Text fontSize="x-large">{icon}</Text>
        <Text>{label}</Text>
      </Stack>
    </FontGridItemWrapper>
  )
}

type FontViewProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>
}
export const FontsView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: FontViewProps) => {
  useRequiredFeatureFlag('customFonts')
  const { currentWorkspace } = useUserContext()
  const [currentFont, setCurrentFont] = useState<Font | null>(null)

  const {
    data: fontsData,
    error: fontsError,
    loading: fontsLoading,
  } = useGetWorkspaceFontsQuery({
    variables: { workspaceId: currentWorkspace?.id, archived: false },
    skip: !currentWorkspace,
  })

  const {
    isOpen: isFontUploadModalOpen,
    onOpen: onFontUploadModalOpen,
    onClose: onFontUploadModalClose,
  } = useDisclosure()

  const {
    isOpen: isFontDeleteModalOpen,
    onOpen: onFontDeleteModalOpen,
    onClose: onFontDeleteModalClose,
  } = useDisclosure()

  const {
    isOpen: isFontPreviewModalOpen,
    onOpen: onFontPreviewModalOpen,
    onClose: onFontPreviewModalClose,
  } = useDisclosure()

  const onEditOrDeleteClick = useCallback(
    (font: Font, action: 'edit' | 'delete') => () => {
      setCurrentFont(font)
      if (action === 'edit') {
        onFontUploadModalOpen()
      } else {
        onFontDeleteModalOpen()
        onFontUploadModalClose()
      }
      onFontPreviewModalClose()
    },
    [
      onFontDeleteModalOpen,
      onFontPreviewModalClose,
      onFontUploadModalOpen,
      onFontUploadModalClose,
    ]
  )

  const onOpenUploadModalClick = useCallback(() => {
    setCurrentFont(null)
    onFontUploadModalOpen()
  }, [onFontUploadModalOpen])

  const onFontTileClick = useCallback(
    (font: Font) => () => {
      setCurrentFont(font)
      onFontPreviewModalOpen()
    },
    [onFontPreviewModalOpen]
  )

  const { displayName, description, icon } = TabMap[SidebarTabs.FONTS]

  const filteredFonts = (fontsData?.fonts || []).filter(
    (font) => !font.archived
  )
  const fontsForLoader = filteredFonts.map((font) => ({
    id: font.id,
    name: font.name,
    url: font.cssUrl,
  }))
  const numOfFonts = filteredFonts.length

  return (
    <Box w="100%" data-testid="fonts-view-container">
      <FontLoader fonts={fontsForLoader} />
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading fontSize="lg" fontWeight="600" data-testid="fonts-view-header">
          {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
        <Text fontSize="md" mt="2" mb="6" color="gray.600">
          {description}
        </Text>
      </TopbarWrapper>
      <Stack>
        <Text color="gray.600">
          {numOfFonts} font {numOfFonts === 1 ? 'family' : 'families'}
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 3, '2xl': 4 }} spacing={6}>
          <FontUploadTile
            label="Upload font family"
            icon={<FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />}
            onClick={onOpenUploadModalClick}
          />
          {!fontsLoading &&
            !fontsError &&
            filteredFonts.map((font) => (
              <FontTile
                key={font.id}
                font={font}
                onEditClick={onEditOrDeleteClick(font, 'edit')}
                onDeleteClick={onEditOrDeleteClick(font, 'delete')}
                onClick={onFontTileClick(font)}
              />
            ))}
        </SimpleGrid>
      </Stack>
      {isFontUploadModalOpen && currentWorkspace && (
        <FontUploadModal
          onClose={onFontUploadModalClose}
          orgId={currentWorkspace.id}
          font={currentFont}
        />
      )}
      {isFontDeleteModalOpen && currentWorkspace && currentFont && (
        <FontDeleteModal
          onClose={onFontDeleteModalClose}
          orgId={currentWorkspace.id}
          font={currentFont}
        />
      )}
      {isFontPreviewModalOpen && currentFont && (
        <FontPreviewModal
          onClose={onFontPreviewModalClose}
          onEditClick={onEditOrDeleteClick(currentFont, 'edit')}
          onDeleteClick={onEditOrDeleteClick(currentFont, 'delete')}
          font={currentFont}
        />
      )}
    </Box>
  )
}
