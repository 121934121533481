import { Text, Alert, Code, AlertIcon } from '@chakra-ui/react'

export const EmptyFamilyWarning = () => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <Text>
        <strong>
          This font family is empty{' '}
          <span role="img" aria-label="Crying emoji">
            😢
          </span>
          .
        </strong>{' '}
        It will remain hidden from the list of available fonts in the custom
        theme creator until you upload one{' '}
        <Code colorScheme="purple">.ttf</Code> or{' '}
        <Code colorScheme="pink">.otf</Code> file
      </Text>
    </Alert>
  )
}
