import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'

import {
  ExistingWorkspace,
  SortDirection,
  useGetWorkspaceMembersQuery,
  User,
  useUpdateMemberRoleMutation,
  WorkspaceMembership,
  WorkspaceMembersSortField,
  WorkspaceRole,
} from 'modules/api'
import { useUserContext } from 'modules/user'
import {
  workspaceRoleMap,
  WORKSPACE_ADMIN_DISPLAY_NAME,
} from 'modules/workspaces/constants'

import { WorkspaceRoleMenu } from './WorkspaceRoleMenu'

const MEMBERS_PAGE_SIZE = 50

const findCurrentWorkspaceMembership = (
  id: ExistingWorkspace['id'],
  workspaceMemberships?: WorkspaceMembership[]
): WorkspaceMembership | undefined => {
  return (workspaceMemberships || []).find((w) => w.workspace?.id === id)
}

export const MembersList = ({
  workspace,
  dateFormatterFn,
  canManageWorkspace,
}: {
  workspace: ExistingWorkspace
  dateFormatterFn: (date: string) => string
  canManageWorkspace: boolean
}) => {
  const [sortField, setSortField] = useState<WorkspaceMembersSortField>(
    WorkspaceMembersSortField.DisplayName
  )
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  )
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)
  const variables = {
    id: workspace?.id,
    first: MEMBERS_PAGE_SIZE,
    sortBy: {
      field: sortField,
      direction: sortDirection,
    },
  }
  const { data, loading, fetchMore } = useGetWorkspaceMembersQuery({
    variables,
    skip: !workspace?.id,
  })

  const [updateMemberRole, { loading: isUpdatingMemberRole }] =
    useUpdateMemberRoleMutation()

  const pageInfo = data?.workspaceMembers?.pageInfo
  const showPaginationControls =
    !loading && pageInfo?.hasNextPage && pageInfo?.endCursor
  const members = data?.workspaceMembers.edges.map((edge) => edge.node)
  const { user, refetch } = useUserContext()

  const toast = useToast()

  // TODO: get adminCount from API instead
  // https://linear.app/gamma-app/issue/G-3925/membercount-and-admincount-return-null-from-getworkspace-query
  const adminCount = useMemo(
    () =>
      members?.reduce((count, member) => {
        const currentWorkspaceMembership = findCurrentWorkspaceMembership(
          workspace?.id,
          member.workspaceMemberships
        )
        if (
          currentWorkspaceMembership &&
          currentWorkspaceMembership.role === WorkspaceRole.Admin
        ) {
          count++
        }
        return count
      }, 0) || 0,
    [members, workspace.id]
  )

  const handleMemberRoleChange = useCallback(
    (member?: User) => (option: WorkspaceRole) => {
      if (!member || !canManageWorkspace) {
        return
      }

      updateMemberRole({
        variables: {
          workspaceId: workspace.id,
          userId: member.id,
          role: option,
        },
      })
        .then(() => {
          refetch?.()
          toast({
            description: `Workspace role for ${member.displayName} (${
              member.email
            }) updated to ${workspaceRoleMap[option].title.toLowerCase()}`,
            status: 'success',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        })
        .catch(() => {
          toast({
            description: `Unable to update workspace role for ${member.displayName} (${member.email})`,
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        })
    },
    [canManageWorkspace, refetch, toast, updateMemberRole, user, workspace.id]
  )

  return (
    <>
      <TableContainer>
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="sm"
          whiteSpace="normal"
        >
          <Thead>
            <Tr>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.DisplayName)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.DisplayName
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.DisplayName
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  Name
                </Button>
              </Th>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.CreatedTime)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.CreatedTime
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.CreatedTime
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  Join date
                </Button>
              </Th>
              <Th>Role</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <Tr p={0}>
                <Td colSpan={2} p={0}>
                  <Stack spacing={2}>
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                  </Stack>
                </Td>
              </Tr>
            )}

            {members &&
              members?.length > 0 &&
              members.map((member, i) => {
                const isLastRow = i === members.length - 1
                const currentWorkspaceMembership =
                  findCurrentWorkspaceMembership(
                    workspace.id,
                    member.workspaceMemberships
                  )
                const createdTime = currentWorkspaceMembership?.createdTime
                const formattedCreatedTime = dateFormatterFn(createdTime)
                const memberRole = currentWorkspaceMembership?.role
                const isAdmin = memberRole === WorkspaceRole.Admin
                const memberRoleTitle = memberRole
                  ? workspaceRoleMap[memberRole].title
                  : ''

                return (
                  <Tr key={member.id}>
                    <Td borderBottom={isLastRow ? '0 none' : undefined}>
                      <UserAvatarBlock
                        user={member}
                        isSelf={member.id === user?.id}
                      />
                    </Td>
                    <Td
                      borderBottom={isLastRow ? '0 none' : undefined}
                      whiteSpace="nowrap"
                    >
                      {formattedCreatedTime}
                    </Td>
                    <Td borderBottom={isLastRow ? '0 none' : undefined}>
                      {isUpdatingMemberRole ? (
                        <Spinner size="sm" />
                      ) : canManageWorkspace && memberRole ? (
                        <WorkspaceRoleMenu
                          selected={memberRole}
                          options={[WorkspaceRole.Admin, WorkspaceRole.Member]}
                          onSelect={handleMemberRoleChange(member)}
                          disabledOptions={
                            isAdmin && adminCount <= 1
                              ? [
                                  {
                                    disabledOption: WorkspaceRole.Member,
                                    reason: `A workspace must have at least one ${WORKSPACE_ADMIN_DISPLAY_NAME}`,
                                  },
                                ]
                              : []
                          }
                        />
                      ) : (
                        memberRoleTitle
                      )}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {showPaginationControls && (
        <Flex w="100%" justify="center" align="center" mt={3}>
          <Button
            isLoading={isFetchingMore}
            size="sm"
            onClick={() => {
              setIsFetchingMore(true)
              fetchMore({
                variables: {
                  after: pageInfo?.endCursor,
                },
              }).finally(() => {
                setIsFetchingMore(false)
              })
            }}
          >
            Load more
          </Button>
        </Flex>
      )}
    </>
  )
}

type UserAvatarBlock = {
  user: User
  isSelf: boolean
}
const UserAvatarBlock = ({ user, isSelf }: UserAvatarBlock) => {
  const { displayName, profileImageUrl, email } = user
  return (
    <HStack>
      <Avatar size="sm" name={displayName} src={profileImageUrl} />
      <Stack spacing={0}>
        <Text>
          {displayName}
          {isSelf ? ' (you)' : ''}
        </Text>
        <HStack mt={1}>
          <Text fontSize="xs" color="gray.500">
            {email}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  )
}
