import {
  Box,
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { useCallback, useState } from 'react'

import { useUpdateUserMutation } from 'modules/api'
import { useUserContext } from 'modules/user'

const ORDERED_JOB_FUNCTIONS = [
  // https://docs.google.com/spreadsheets/d/1HUMKWXHhOHNeFV8JP2H1uKifjqtwc7wxoymb91Q7jfQ/edit#gid=0
  { id: 'consulting', label: 'Consultant' },
  { id: 'creator', label: 'Creator' },
  { id: 'customer_service', label: 'Customer service' },
  { id: 'data', label: 'Data' },
  { id: 'design', label: 'Design' },
  { id: 'education', label: 'Education (educator)' },
  { id: 'education', label: 'Education (student)' },
  { id: 'engineering', label: 'Engineering' },
  { id: 'finance', label: 'Finance' },
  { id: 'human_resources', label: 'HR' },
  { id: 'leadership', label: 'Leadership' },
  { id: 'legal', label: 'Legal' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'operations', label: 'Operations' },
  { id: 'product', label: 'Product' },
  { id: 'recruiting', label: 'Recruiting' },
  { id: 'sales', label: 'Sales' },
  { id: 'other', label: 'Other' },
]

type JobFunctionCategories = 'work' | 'education' | 'personal'
type UserInfoContext = 'get-started' | 'modal'
type UserInfoContextConfig = {
  [key in UserInfoContext]: {
    saveButtonText: string
    formControlMaxW: FormControlProps['maxWidth']
    categoryQuestion: string
  }
}
const contextConfig: UserInfoContextConfig = {
  'get-started': {
    saveButtonText: 'Get started',
    formControlMaxW: ['100%', '80%', '50%'],
    categoryQuestion: 'How do you plan to use Gamma?',
  },
  modal: {
    saveButtonText: 'Submit',
    formControlMaxW: ['100%', '60%'],
    categoryQuestion: 'How are you using Gamma?',
  },
}
const ORDERED_JOB_FUNCTION_CATEGORIES: {
  id: JobFunctionCategories
  label: string
}[] = [
  {
    id: 'work',
    label: 'For work',
  },
  {
    id: 'education',
    label: 'For school',
  },
  {
    id: 'personal',
    label: 'For personal use ',
  },
]

export const UserInfo = ({
  onSuccess,
  onError,
  context = 'get-started',
}: {
  onSuccess?: () => void
  onError?: () => void
  context?: UserInfoContext
}) => {
  const { user, refetch: refetchUser } = useUserContext()
  const [isSaving, setIsSaving] = useState(false)
  const [category, setCategory] = useState<JobFunctionCategories | undefined>(
    undefined
  )
  const [referrer, setReferrer] = useState<string | undefined>(undefined)
  const [jobFunction, setJobFunction] = useState<string | undefined>(undefined)
  const [updateUser, { loading, error }] = useUpdateUserMutation()

  const isSaveDisabled =
    isSaving || Boolean(error) || (category !== 'education' && !jobFunction)

  const isSaveLoading = loading || isSaving

  const showDisabledButtonTooltip = category && !jobFunction

  const onSaveClick = useCallback(() => {
    updateUser({
      variables: {
        input: {
          id: user?.id as string,
          referrer,
          jobFunction,
        },
      },
    })
      .then(() => {
        // We need to granularly control the state of the saving button such that someone can't click it while the onSuccess call is in flight
        setIsSaving(true)
        return refetchUser?.()
      })
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((e) => {
        console.error(
          `[GetStarted.UserInfo] Error updating user info "${user?.id}"`,
          e.message
        )
        setIsSaving(false)
        if (onError) {
          onError()
        }
      })
  }, [
    jobFunction,
    onError,
    onSuccess,
    referrer,
    refetchUser,
    updateUser,
    user?.id,
  ])

  const { formControlMaxW, saveButtonText, categoryQuestion } =
    contextConfig[context]
  return (
    <Box w="100%">
      <Stack pt={6} alignItems="center" spacing={6} alignSelf="center" mb={10}>
        <FormControl maxW={formControlMaxW} as="fieldset" isRequired>
          <FormLabel>{categoryQuestion}</FormLabel>
          <RadioGroup
            onChange={(val: JobFunctionCategories) => {
              setCategory(val)
              if (val === 'education') {
                setJobFunction('education')
              } else {
                // Reset if you chose education then changed your mind
                setJobFunction(undefined)
              }
            }}
            value={category}
          >
            <Stack direction="column" spacing={2}>
              {ORDERED_JOB_FUNCTION_CATEGORIES.map(({ id, label }) => {
                return (
                  <Radio key={id} value={id}>
                    {label}
                  </Radio>
                )
              })}
            </Stack>
          </RadioGroup>
        </FormControl>

        {category && (
          <>
            {category !== 'education' && (
              <FormControl maxW={formControlMaxW} isRequired>
                <FormLabel>What kind of work do you do?</FormLabel>
                <Select
                  placeholder="Select option"
                  isRequired
                  onChange={(e) => {
                    setJobFunction(() => e.target.value)
                  }}
                >
                  {ORDERED_JOB_FUNCTIONS.map(({ label, id }, index) => (
                    <option key={`${id}-${index}`} value={id}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl maxW={formControlMaxW}>
              <FormLabel>How did you hear about us?</FormLabel>
              <Input
                size="lg"
                type="text"
                value={referrer}
                onChange={(e) => {
                  setReferrer(e.target.value)
                }}
              />
              <FormHelperText fontSize="xs">
                {referrer && referrer?.length > 4 ? (
                  <>
                    Grant says thank you&nbsp;
                    <span role="img" aria-label="thanks">
                      🙏
                    </span>
                  </>
                ) : (
                  <>
                    (Optional) You'll make our CEO Grant happy&nbsp;
                    <span role="img" aria-label="smile">
                      😊
                    </span>
                  </>
                )}
              </FormHelperText>
            </FormControl>
          </>
        )}

        <Stack alignItems="center" spacing={4} pt={4}>
          <GammaTooltip
            label="Please select a role from the dropdown"
            isDisabled={!showDisabledButtonTooltip}
            placement="top"
          >
            <Box>
              <Button
                variant="solid"
                size="lg"
                isDisabled={isSaveDisabled}
                isLoading={isSaveLoading}
                onClick={onSaveClick}
              >
                {saveButtonText}
              </Button>
            </Box>
          </GammaTooltip>
        </Stack>
      </Stack>
    </Box>
  )
}
