import { Box, Center, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import NextImage from 'next/legacy/image'
import NextLink from 'next/link'

import { INITIAL_TAB } from '../constants'

export const DocsEmptyState = () => {
  return (
    <Center minH="sm">
      <Flex direction="column" justify={'center'} align="center">
        <Box w="80%" maxW="250px" mb={6} alignContent="center">
          <NextImage
            src="/images/Sal-Fishing-2x.png"
            width="640"
            height="361"
            alt="Sal, the Gamma mascot, fishing extraterrestrially"
          />
        </Box>
        <Heading size="md" mb={2} textAlign="center">
          No {DOC_DISPLAY_NAME_PLURAL} found.
        </Heading>
        <Text fontSize="lg" color="gray.400" textAlign="center">
          Try changing your filters, or{' '}
          <NextLink href={`/#${INITIAL_TAB}`} legacyBehavior>
            <Link color="trueblue.500">view all {DOC_DISPLAY_NAME_PLURAL}</Link>
          </NextLink>
          .
        </Text>
      </Flex>
    </Center>
  )
}
