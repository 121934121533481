import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useCallback } from 'react'

import { useGetThemesQuery } from 'modules/api'
import { useRequiredFeatureFlag } from 'modules/featureFlags/hooks/useRequiredFeatureFlag'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { Theme, ThemeType } from 'modules/theming'
import { ThemeEditorDrawer } from 'modules/theming/components/ThemeEditorDrawer'
import { ThemePreview } from 'modules/theming/components/ThemePreview'
import { useThemeReducer } from 'modules/theming/themeReducer/reducer'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { useUserContext } from 'modules/user'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'
import { GridItemButton } from './GridItemButton'

type ThemesViewProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}

export const ThemesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: ThemesViewProps) => {
  const [state, dispatch] = useThemeReducer()
  const { currentWorkspace } = useUserContext()
  const {
    isOpen: isThemeEditorOpen,
    onOpen: onThemeEditorOpen,
    onClose: onThemeEditorClose,
  } = useModalDisclosure({ id: 'themeEditor' })

  useRequiredFeatureFlag('themes2')

  const openThemeEditor = useCallback(
    (themeToEdit?: Theme) => {
      if (themeToEdit) {
        dispatch({ type: 'SET_THEME_EDITING', data: { theme: themeToEdit } })
      } else {
        dispatch({ type: 'NEW_THEME' })
      }
      onThemeEditorOpen()
    },
    [dispatch, onThemeEditorOpen]
  )

  const closeThemeEditor = useCallback(() => {
    dispatch({ type: 'THEME_RESET' })
    onThemeEditorClose()
  }, [dispatch, onThemeEditorClose])

  const {
    data: workspaceData,
    loading,
    called,
  } = useGetThemesQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      archived: false,
    },
    skip: !currentWorkspace,
  })
  const { data: standardData } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
  })

  const showLoading = !called || loading
  const workspaceThemes = workspaceData?.themes || []
  const standardThemes = standardData?.themes || []

  const { displayName, description, icon } = TabMap[SidebarTabs.THEMES]

  return (
    <Box w="100%" data-testid="themes-view-container">
      <GlobalCardStyles />
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading
          fontSize="lg"
          fontWeight="600"
          data-testid="themes-view-header"
        >
          {icon && <FontAwesomeIcon icon={icon} aria-label={displayName} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
        <Text fontSize="md" mt="2" mb="6" color="gray.600">
          {description}
        </Text>
      </TopbarWrapper>
      {showLoading ? (
        <Box width="100%" data-testid="themes-skeleton">
          <SimpleGrid columns={[1, 1, 2, 3, 5]} spacing={6}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <GridItem
                key={i}
                bg="linen.50"
                borderRadius="5px"
                height="144px"
                as={Skeleton}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <>
          <ThemesGrid
            themes={workspaceThemes}
            heading="Custom themes"
            description="These themes are custom to your workspace."
            openThemeEditor={openThemeEditor}
            type="custom"
          />
          <ThemesGrid
            themes={standardThemes}
            heading="Standard themes"
            description="These themes are created by Gamma."
            openThemeEditor={openThemeEditor}
            type="standard"
          />
        </>
      )}
      <ThemeEditorDrawer
        isOpen={isThemeEditorOpen}
        onClose={closeThemeEditor}
        state={state}
        dispatch={dispatch}
        disableDrawerTransition
      />
    </Box>
  )
}

type ThemesGridProps = {
  themes: Theme[]
  heading: string
  description: string
  type: ThemeType
  openThemeEditor?: (themeToEdit?: Theme | undefined) => void
}

const ThemesGrid = ({
  themes,
  type,
  heading,
  description,
  openThemeEditor,
}: ThemesGridProps) => {
  const isCustom = type === 'custom'

  return (
    <Stack my={12}>
      <Stack mb={6} fontSize="md">
        <Heading fontSize="2xl" mb={-1}>
          {heading}
        </Heading>
        <Text color="gray.600">{description}</Text>
      </Stack>
      <SimpleGrid columns={[1, 1, 2, 3, 5]} spacing={6}>
        {isCustom && (
          <GridItemButton
            label="New theme"
            icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
            onClick={() => openThemeEditor?.()}
          />
        )}
        {themes.map((theme) => {
          return (
            <ThemePreview
              key={theme.id}
              type={type}
              theme={theme}
              isChecked={false}
              onEditClick={openThemeEditor}
              onThemeClicked={isCustom ? openThemeEditor : undefined}
            />
          )
        })}
      </SimpleGrid>
    </Stack>
  )
}
