import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

type FontGridItemWrapperProps = { children: ReactNode } & FlexProps

export const FontGridItemWrapper = ({
  children,
  ...rest
}: FontGridItemWrapperProps) => {
  return (
    <Flex
      border="1px solid"
      borderColor="gray.200"
      minH={36}
      maxH={36}
      minW="100%"
      borderRadius="lg"
      alignItems="center"
      justifyContent="center"
      transition="all"
      transitionDuration="normal"
      _hover={{
        background: 'white',
        color: 'trueblue.600',
        cursor: 'pointer',
      }}
      {...rest}
    >
      {children}
    </Flex>
  )
}
