import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect } from 'react'

import { useUserContext } from 'modules/user'
import { UserInfo } from 'modules/user/components'
import salTetherImg from 'publicImages/Sal-Tether.svg'

export const UserInfoModalWrapper = () => {
  const { onClose, onOpen, isOpen } = useDisclosure()
  const { user, isUserLoading } = useUserContext()

  const shouldShowModal = Boolean(user && !isUserLoading && !user.jobFunction)
  useEffect(() => {
    if (shouldShowModal) {
      setTimeout(onOpen, 1000)
    }
  }, [shouldShowModal, onOpen])

  if (!isOpen) {
    return <></>
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tell us about yourself</ModalHeader>
        <ModalBody>
          <Stack>
            <Flex
              height={32}
              justifyContent="center"
              background="radial-gradient(circle at center, var(--chakra-colors-pink-200) 0, var(--chakra-colors-salmon-200) 110%)"
              borderRadius="md"
              pt={4}
              px={4}
            >
              <Image
                src={salTetherImg.src}
                alt="Sal floating on a pink gradient"
              />
            </Flex>
            <Text fontSize="lg" pb={0} pt={4}>
              We'd love to learn more about you so we can better tailor our
              product and prioritize new features.
            </Text>
            <Text fontSize="sm" color="gray.500" textAlign="center">
              (Don't worry, this will take 5 seconds{' '}
              <span role="img" aria-label="stopwatch">
                ⏱
              </span>
              )
            </Text>
            <UserInfo context="modal" onSuccess={onClose} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
