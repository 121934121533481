import Uppy from '@uppy/core'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { imageTypes } from '..'

/* http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
const hiddenInputStyle = {
  width: '0.1px',
  height: '0.1px',
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1,
}

/**
 * Helper hook to manage a hidden file input
 * Inspired by the Uppy file input plugin
 * https://github.com/transloadit/uppy/blob/6e4fd9556d1bd83a3ed424721961a8aa2990ea78/packages/%40uppy/file-input/src/FileInput.jsx
 */
export const useFileInput = ({
  uppy,
  multiple = false,
  accept = imageTypes.join(','),
}: {
  uppy: Uppy
  multiple?: boolean
  accept?: string
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null)
  const inputElement = useMemo(
    () => (
      <input
        ref={hiddenFileInputRef}
        className="gamma-uppy-FileInput-input"
        // @ts-ignore
        style={hiddenInputStyle}
        type="file"
        name="gamma-file-input"
        multiple={multiple}
        accept={accept}
        onChange={(event) => {
          const target = event.target as HTMLInputElement
          if (!target || !target.files) return

          const files = Array.from(target.files)
          files.forEach((file) => {
            try {
              uppy.addFile({
                source: 'file input',
                name: file.name,
                type: file.type,
                data: file,
              })
            } catch (err) {
              if (err.isRestriction) {
                // handle restrictions
                console.warn('[useFileInput] Restriction error:', err)
              } else {
                // handle other errors
                console.error(err)
              }
            }
          })
          // @ts-ignore
          target.value = null
        }}
      />
    ),
    []
  )

  const onClick = useCallback(() => {
    hiddenFileInputRef.current?.click()
  }, [])

  useEffect(() => {
    const fileInput = hiddenFileInputRef.current
    if (!fileInput) return

    // it’s probably a good idea to clear the `<input>`
    // after the upload or when the file was removed
    // (see https://github.com/transloadit/uppy/issues/2640#issuecomment-731034781)
    uppy.on('file-removed', () => {
      // @ts-ignore
      fileInput.value = null
    })

    uppy.on('complete', () => {
      // @ts-ignore
      fileInput.value = null
    })

    uppy.on('transloadit:complete', () => {
      // @ts-ignore
      fileInput.value = null
    })
  }, [])

  return {
    inputElement,
    onClick,
  }
}
