import React from 'react'

import {
  FontUploadAction,
  FontUploadDispatch,
  FontUploadReducerState,
  FontUploadState,
  UploadFontFile,
} from './types'

const initialFontUploadState = {
  existingFont: null,
  mode: 'create' as const,
  fontId: undefined,
  hasUploadedFonts: false,
  fontName: '',
  uploadFontFiles: [],
}

function fontUploadReducer(
  state: FontUploadState,
  action: FontUploadAction
): FontUploadState {
  switch (action.type) {
    case 'NEW_FONT_UPLOAD': {
      return initialFontUploadState
    }
    case 'EDIT_EXISTING_FONT': {
      const { font } = action.data
      return {
        existingFont: font,
        mode: 'edit',
        fontId: font.id,
        fontName: font.name,
        hasUploadedFonts: true,
        uploadFontFiles: font.fontFiles!.map((fontFile) => ({
          fontFile,
          uploadId: fontFile.id,
          status: 'existing',
          errorMessage: null,
        })),
      }
    }
    case 'RESET_FONT_UPLOAD': {
      return initialFontUploadState
    }
    case 'EDIT_FONT_NAME': {
      const { fontName } = action.data
      return {
        ...state,
        fontName,
      }
    }
    case 'UPLOAD_FONT_FILE_UPLOAD_STARTED': {
      const { data } = action

      const newUploadedFile: UploadFontFile = {
        fontFile: {
          id: data.uploadId,
          name: data.name,
          weight: data.weight,
          isItalic: data.isItalic,
          fileType: data.fileType,
        },
        uploadId: data.uploadId,
        status: 'uploading',
        errorMessage: null,
      }

      const fontName = state.fontName ? state.fontName : data.familyName

      return {
        ...state,
        fontName,
        hasUploadedFonts: true,
        uploadFontFiles: [...state.uploadFontFiles, newUploadedFile],
      }
    }
    case 'UPLOAD_FONT_FILE_UPLOAD_ERROR': {
      const { uploadId, errorMessage } = action.data

      return {
        ...state,
        uploadFontFiles: state.uploadFontFiles.map((uploadedFont) => {
          if (uploadId === uploadedFont.uploadId) {
            return {
              ...uploadedFont,
              status: 'error',
              errorMessage,
            }
          }
          return uploadedFont
        }),
      }
    }
    case 'UPLOAD_FONT_FILE_UPLOAD_SUCCESS': {
      const { uploadId, sourceUrl } = action.data

      return {
        ...state,
        uploadFontFiles: state.uploadFontFiles.map((uploadedFont) => {
          if (uploadId === uploadedFont.uploadId) {
            const fontFile = { ...uploadedFont.fontFile, sourceUrl }
            return {
              ...uploadedFont,
              status: 'created',
              errorMessage: null,
              fontFile,
            }
          }
          return uploadedFont
        }),
      }
    }
    case 'EDIT_UPLOAD_FONT_FILE_WEIGHT': {
      const { uploadId, weight } = action.data
      return {
        ...state,
        uploadFontFiles: state.uploadFontFiles.map((uploadedFont) => {
          if (uploadedFont.uploadId === uploadId) {
            return {
              ...uploadedFont,
              fontFile: {
                ...uploadedFont.fontFile,
                weight,
              },
            }
          }
          return uploadedFont
        }),
      }
    }
    case 'EDIT_UPLOAD_FONT_FILE_STYLE': {
      const { uploadId, isItalic } = action.data
      return {
        ...state,
        uploadFontFiles: state.uploadFontFiles.map((uploadedFont) => {
          if (uploadedFont.uploadId === uploadId) {
            return {
              ...uploadedFont,
              fontFile: {
                ...uploadedFont.fontFile,
                isItalic,
              },
            }
          }
          return uploadedFont
        }),
      }
    }
    case 'DELETE_UPLOAD_FONT_FILE': {
      const { uploadId } = action.data
      return {
        ...state,
        uploadFontFiles: state.uploadFontFiles.filter(
          (uploadedFont) => uploadedFont.uploadId !== uploadId
        ),
      }
    }
    default: {
      const _: never = action
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export function useFontUploadReducer(): [
  FontUploadReducerState,
  FontUploadDispatch
] {
  return React.useReducer(fontUploadReducer, initialFontUploadState)
}
