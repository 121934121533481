import React, { useCallback, useMemo, useRef } from 'react'

import { imageTypes } from 'modules/media'

/* http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
const hiddenInputStyles: React.CSSProperties = {
  width: '0.1px',
  height: '0.1px',
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1,
}

export const useHiddenFileInput = ({
  handleFilesCallback,
  multiple = false,
  accept = imageTypes.join(','),
}: {
  handleFilesCallback: (files: File[]) => void
  multiple?: boolean
  accept?: string
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null)
  const inputElement = useMemo(
    () => (
      <input
        ref={hiddenFileInputRef}
        className="gamma-FileInput-input"
        style={hiddenInputStyles}
        type="file"
        name="gamma-file-input"
        multiple={multiple}
        accept={accept}
        onChange={(event) => {
          const target = event.target as HTMLInputElement
          if (!target || !target.files) return

          const files = Array.from(target.files)

          handleFilesCallback(files)

          // Clear input, from:
          // https://github.com/transloadit/uppy/blob/111d9f3cb2011c832e5be60e57dd6d194e6f7e1d/packages/%40uppy/file-input/src/FileInput.jsx#L62
          // @ts-ignore
          target.value = null
        }}
      />
    ),
    [accept, handleFilesCallback, multiple]
  )

  const onClick = useCallback(() => {
    hiddenFileInputRef.current?.click()
  }, [])

  return {
    inputElement,
    onClick,
  }
}
