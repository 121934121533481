import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'

import {
  FontUploadDispatch,
  FontUploadReducerState,
  UploadFontFile,
} from '../fontUploadReducer/types'
import { FontCategorizationByWeights } from './FontWeightTable'

type FontCategorizationViewProps = {
  fontName: FontUploadReducerState['fontName']
  fontFiles: UploadFontFile[]
  dispatch: FontUploadDispatch
  onFontFileUploadClick: () => void
}

export const FontCategorizationView = ({
  fontName,
  fontFiles,
  dispatch,
  onFontFileUploadClick,
}: FontCategorizationViewProps) => {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'EDIT_FONT_NAME',
        data: { fontName: e.target.value },
      })
    },
    [dispatch]
  )

  const nameInputIsEmpty = fontName.length === 0

  return (
    <ModalBody>
      <VStack align="stretch" mb="4">
        <Flex justifyContent="space-between" gap={6}>
          <FormControl isInvalid={nameInputIsEmpty} flex={2}>
            <FormLabel>Font family name</FormLabel>
            <Input
              type="text"
              placeholder="eg. Papyrus"
              value={fontName}
              onChange={handleInputChange}
            />
            {nameInputIsEmpty && (
              <FormErrorMessage alignSelf="flex-start">
                Please give your font family a name
              </FormErrorMessage>
            )}
            {/* {nameIsNotUnique && (
                <FormErrorMessage>
                  Your workspace already has a font family with this name
                </FormErrorMessage>
              )} */}
          </FormControl>
          <Stack mt={8} flex={1}>
            <Button
              leftIcon={
                <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
              }
              onClick={onFontFileUploadClick}
            >
              Add more files
            </Button>
            <Text textAlign="center" fontSize="xs" color="gray.600">
              Gamma supports .ttf and .otf fonts
            </Text>
          </Stack>
        </Flex>
        <FontCategorizationByWeights
          fontFiles={fontFiles}
          dispatch={dispatch}
        />
      </VStack>
    </ModalBody>
  )
}
